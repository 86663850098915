import {
  Auth0Provider,
  Auth0ProviderOptions,
  User,
  useAuth0,
} from "@auth0/auth0-react";
import AuthContext, { AuthContextInterface } from "./context";
import { CustomerGroup, VinoUser, initialAuthState } from "./state";
import { DataItem, InsertData } from "../../../api/src/services/dynamodb";
import {
  ErrorNotifier,
  errorDataBuilder,
} from "../../../api/src/utils/error-notifier";
import {
  IGetAddresses,
  IGetStoredInstruments,
} from "./../../../api/src/services/vino-api-services";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { AuthResolver } from "./resolver";
import { DynamoDB } from "aws-sdk";
import contextLogger from "../../utils/context-logger";
import isBrowser from "../../utils/is-browser";
import { logError } from "../../utils/logger";
import { navigate } from "gatsby";
import { reducer } from "./reducer";
import { trackFavouriteEvent } from "../../utils/event-tracking";
import { useLocation } from "@reach/router";
import vinoFetch from "../../utils/vinoFetch";

type AuthProviderProps = { children?: ReactNode };

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const {
    error,
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect: auth0Login,
    logout: auth0Logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [state, dispatch] = useReducer(
    process.env.GATSBY_VM_ENV !== "production"
      ? contextLogger(reducer)
      : reducer,
    initialAuthState
  );
  // const [authUser, setAuthUser] = useState<VinoUser>(user as VinoUser);
  // const [referralCode, setReferralCode] = useState<string>("");
  // const [checkingReferralCode, setCheckingReferralCode] = useState<boolean>(
  //   true
  // );
  // const [fetchingFavourites, setFetchingFavourites] = useState<boolean>(true);
  // const [favourites, setFavourites] = useState<Array<DataItem> | undefined>([]);

  const location = useLocation();

  const login = useCallback(
    async (appState: { returnTo: string }) => {
      await auth0Login({
        appState,
        fragment: `/auth/login/using-password?returnTo=${appState.returnTo}`,
      });
    },
    [auth0Login]
  );

  const join = useCallback(
    async (appState: { returnTo: string }) => {
      await auth0Login({
        appState,
        fragment: `/auth/join?returnTo=${appState.returnTo}`,
      });
    },
    [auth0Login]
  );

  const logout = useCallback(() => {
    const userId =
      process.env.GATSBY_VM_MARKET +
      "-" +
      (state.user?.["https://www.vinomofo.com/app_metadata"]?.[
        `${process.env.GATSBY_VM_MARKET}_EXTERNAL_ID`
      ] || "");
    auth0Logout({ returnTo: `${location.origin}` });
    if (
      typeof window !== "undefined" &&
      typeof window.analytics !== "undefined"
    ) {
      window.analytics.track("User Logout", {
        category: "Auth",
        userId,
      });
    }
  }, [auth0Logout, state.user]);

  const getAccessToken = useCallback(() => {
    if (isAuthenticated) {
      const ignoreAuthTokenCache =
        localStorage.getItem("refreshAuthToken") === "yes";
      localStorage.setItem("refreshAuthToken", "no");
      return getAccessTokenSilently({
        ignoreCache: ignoreAuthTokenCache,
      });
    }
  }, [isAuthenticated]);

  const getTokenID = useCallback(async () => {
    if (isAuthenticated) {
      const claims = await getIdTokenClaims();
      const idToken = claims?.__raw;
      return idToken;
    }
  }, [isAuthenticated]);

  const fetchCustomerGroup = async (
    customerID?: number | string
  ): Promise<CustomerGroup> => {
    const { data } = await vinoFetch.get("/api/get-customer-group", {
      params: {
        customerID: customerID,
      },
    });
    return data;
  };

  const getAddressesByCustomerId = async ({
    customerID,
  }: IGetAddresses): Promise<any> => {
    const accessToken = await getAccessTokenSilently();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const { data } = await vinoFetch.get(`/api/get-addresses-by-customerid`, {
      headers,
      params: {
        customerID,
      },
    });
    return data;
  };

  const getStoredInstrumentsByCustomerId = async ({
    customerID,
    defaultOnly,
  }: IGetStoredInstruments): Promise<any> => {
    const accessToken = await getAccessTokenSilently();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const { data } = await vinoFetch.get(
      `/api/get-stored-instruments-by-customerid`,
      {
        headers,
        params: {
          customerID,
          defaultOnly,
        },
      }
    );
    return data;
  };

  const validateEmail = async (email: string): Promise<boolean> => {
    const { exists } = await AuthResolver.validateEmail(email);

    return exists;
  };

  const updateProfile = async (payload: {
    given_name: string;
    family_name: string;
    email: string;
    phone: string;
    birthdate: string;
  }): Promise<{ error?: Error }> => {
    const token = await getAccessTokenSilently();
    const tokenid = await getTokenID();

    return await AuthResolver.updateProfile(payload, token, tokenid);
  };

  const changeEmailStepTwo = async (payload: {
    email: string;
  }): Promise<{ error?: Error }> => {
    const token = await getAccessTokenSilently();

    return await AuthResolver.changeEmailStepTwo(payload, token);
  };

  const changeEmailStepThree = async (payload: {
    email: string;
    otp: string;
  }): Promise<{ error?: Error }> => {
    const token = await getAccessTokenSilently();

    const response = await AuthResolver.changeEmailStepThree(payload, token);

    if (!error && state.user?.email !== payload.email)
      dispatch({
        type: "SET_USER",
        user: { ...state.user, email: payload.email },
      });

    // setAuthUser({
    //   ...authUser,
    //   email: payload.email,
    // });

    return response;
  };

  const hasSocialLogin = async (email: string): Promise<boolean> => {
    const { isSocial } = await AuthResolver.hasSocialLogin(email);

    return isSocial;
  };

  const setBigCommerceId = async (user: User): Promise<any> => {
    const userAppMeta = user?.["https://www.vinomofo.com/app_metadata"];
    const customer_bigcommerce_id =
      userAppMeta?.[`BIGCOMMERCE_${process.env.GATSBY_VM_MARKET}_CUSTOMER_ID`];
    if (!customer_bigcommerce_id && user?.email) {
      // This is because the auth0 functions are still creating the customer in the background. So get it straight from BigCommerce
      try {
        const bcCustomer = await vinoFetch.get(
          "/api/get-customer-id-by-email",
          {
            params: {
              email: user.email,
              first_name: user.given_name,
              last_name: user.family_name,
            },
          }
        );
        return bcCustomer?.data[0]?.id;
      } catch (error) {
        return 0;
      }
    } else {
      return customer_bigcommerce_id;
    }
  };

  const rePopulateUser = async (user: VinoUser) => {
    const userAppMeta = user?.["https://www.vinomofo.com/app_metadata"];
    const customer_loyalty_tier =
      userAppMeta?.[`LOYALTY_TIER_${process.env.GATSBY_VM_MARKET}`];
    if (user)
      dispatch({
        type: "SET_USER",
        user: { ...user, loyalty_tier: customer_loyalty_tier || "GREEN" },
      });
    // setAuthUser({ ...user, loyalty_tier: customer_loyalty_tier || "GREEN" });
  };

  const checkReferralCode = async (customer_bigcommerce_id): Promise<any> => {
    dispatch({ type: "SETTING_REFERRAL_CODE" });
    try {
      const accessToken = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      // Check for referral code in customer attribute
      const {
        data: { code: referralCode },
      } = await vinoFetch.get("/api/check-referral-code", {
        headers,
        params: {
          bigcommerceId: customer_bigcommerce_id,
        },
      });
      if (referralCode) {
        dispatch({
          type: "SET_REFERRAL_CODE",
          customerReferralCode: referralCode,
        });
        // setReferralCode(referralCode);
        // setCheckingReferralCode(false);
        return referralCode;
      } else {
        dispatch({
          type: "SET_REFERRAL_CODE",
          customerReferralCode: "",
        });
        // setCheckingReferralCode(false);
        return "";
      }
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      dispatch({
        type: "SET_REFERRAL_CODE",
        customerReferralCode: "",
      });
      // setCheckingReferralCode(false);
      return "";
    }
  };

  const getFavouriteUserId = (): string | null => {
    return isAuthenticated && user?.email
      ? user.email
      : localStorage.getItem("favouriteId");
  };

  const fetchFavourites = async (
    lastEvaluatedID?: string
  ): Promise<DynamoDB.DocumentClient.ScanOutput> => {
    dispatch({ type: "SETTING_FAVOURITES" });
    // setFetchingFavourites(true);
    const favouriteUserId = getFavouriteUserId();

    if (!favouriteUserId) {
      dispatch({ type: "SET_FAVOURITES", favourites: [] });
      // setFetchingFavourites(false);
      return { Items: [], Count: 0 };
    }

    try {
      const accessToken = await getAccessToken();
      const headers =
        isAuthenticated && accessToken
          ? { Authorization: `Bearer ${accessToken}` }
          : {};
      const { data } = await vinoFetch.get("/api/get-favourite-items", {
        headers,
        params: {
          user_id: favouriteUserId,
          lastEvaluatedKey: lastEvaluatedID || undefined,
        },
      });

      if (data) {
        const doUpdate =
          JSON.stringify(state.favourites) !== JSON.stringify(data.Items) ||
          state.favourites.lengh === data.Items.lengh;
        if (doUpdate)
          dispatch({
            type: "SET_FAVOURITES",
            favourites: data.Items,
          });
        // if (isChanged) setFavourites(data.Items);
      }
      // setFetchingFavourites(false);
      return data;
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      dispatch({
        type: "SET_FAVOURITES",
        favourites: [],
      });
      // setFetchingFavourites(false);
      return { Items: [], Count: 0 };
    }
  };

  const addToFavourites = async (
    data: InsertData
  ): Promise<DataItem | undefined> => {
    const { user_id, ...rest } = data;
    if (user_id) {
      try {
        const accessToken = await getAccessToken();
        const headers =
          isAuthenticated && accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : {};

        const { data: newFavourite } = await vinoFetch.post(
          "/api/create-or-delete-favourite-item",
          data,
          {
            headers,
          }
        );
        if (newFavourite) {
          dispatch({
            type: "SET_FAVOURITES",
            favourites:
              state.favourites !== undefined
                ? [...state.favourites, newFavourite]
                : [newFavourite],
          });
          trackFavouriteEvent("Added to Favourites", {
            offer_name: newFavourite.offer_name,
            sku_code: newFavourite.sku_code,
            wine_name: newFavourite.wine_name,
          });
          // setFavourites(
          //   favourites !== undefined
          //     ? [...favourites, newFavourite]
          //     : [newFavourite]
          // );
          // await fetchFavourites();
        }

        return newFavourite;
      } catch (error) {
        logError(error.message, { error });
        const statusCode = error.response ? error.response.status : 400;
        ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
        dispatch({
          type: "SET_FAVOURITES",
          favourites: [],
        });
        // setFetchingFavourites(false);
        return undefined;
      }
    }
    //else login({ returnTo: location.pathname });
  };

  const removeToFavourites = async (
    id: string
  ): Promise<{ message: string } | undefined> => {
    try {
      const accessToken = await getAccessToken();
      const headers =
        isAuthenticated && accessToken
          ? { Authorization: `Bearer ${accessToken}` }
          : {};

      const { data } = await vinoFetch.post(
        "/api/create-or-delete-favourite-item",
        { id },
        {
          headers,
        }
      );
      if (data && id) {
        dispatch({
          type: "SET_FAVOURITES",
          favourites: state.favourites?.filter(
            (fav: DataItem) => !(fav.id === id)
          ),
        });
        const removed = state.favourites?.find(
          (fav: DataItem) => fav.id === id
        );
        trackFavouriteEvent("Removed from Favourites", {
          offer_name: removed.offer_name,
          sku_code: removed.sku_code,
          wine_name: removed.wine_name,
        });
        // setFavourites(favourites?.filter((fav) => !(fav.id === id)));
        // await fetchFavourites();
      }

      return data;
    } catch (error) {
      logError(error.message, { error });
      const statusCode = error.response ? error.response.status : 400;
      ErrorNotifier.notify(error, errorDataBuilder("FE", statusCode, error));
      dispatch({
        type: "SET_FAVOURITES",
        favourites: [],
      });
      // setFetchingFavourites(false);
      return undefined;
    }
  };

  // useEffect(() => {
  //   dispatch({
  //     type: "SET_AUTH0_STATE",
  //     isLoading: isLoading,
  //     isAuthenticated: isAuthenticated,
  //     error: error,
  //   });
  // }, [error, isAuthenticated, isLoading]);

  useEffect(() => {
    if (user) {
      if (isAuthenticated && !isLoading)
        dispatch({
          type: "SET_USER",
          user: user,
        });

      if (!state.user) {
        const APP_META_KEY = "https://www.vinomofo.com/app_metadata";
        const userAppMeta = user?.[APP_META_KEY];
        const customer_loyalty_tier =
          userAppMeta?.[`LOYALTY_TIER_${process.env.GATSBY_VM_MARKET}`];
        const customer_bigcommerce_id =
          userAppMeta?.[
            `BIGCOMMERCE_${process.env.GATSBY_VM_MARKET}_CUSTOMER_ID`
          ];
        // const customer_hubspot_id =
        //   userAppMeta?.[`HUBSPOT_${process.env.GATSBY_VM_MARKET}_CONTACT_ID`];

        const attachCustomerGroupAddressesAndCard = async () => {
          await setBigCommerceId(user);
          const group = await fetchCustomerGroup(customer_bigcommerce_id);
          const addresses = await getAddressesByCustomerId({
            customerID: customer_bigcommerce_id,
          });
          const cards = await getStoredInstrumentsByCustomerId({
            customerID: customer_bigcommerce_id,
            defaultOnly: true,
          });

          const defaultCard = cards.find((card) => card.is_default);
          let newProfile = {
            ...user,
            customer_group: group,
            addresses: addresses,
            stored_instruments: defaultCard ? [defaultCard] : [cards[0]],
          };

          if (group?.name !== customer_loyalty_tier) {
            newProfile = {
              ...newProfile,
              // [APP_META_KEY]: { ...userAppMeta, LOYALTY_TIER_AU: group.name },
            };
          }
          dispatch({
            type: "SET_USER",
            user: newProfile as VinoUser,
          });
          // setAuthUser(newProfile as VinoUser);
          await fetchFavourites();
        };

        if (customer_bigcommerce_id) {
          if (
            !user?.customer_group &&
            !user?.addresses &&
            !user?.stored_instruments
          )
            attachCustomerGroupAddressesAndCard();

          if (!state.customerReferralCode)
            checkReferralCode(customer_bigcommerce_id);
        }
      }
    } else {
      if (!isAuthenticated && !isLoading) fetchFavourites();
    }
  }, [user, isAuthenticated, isLoading]);

  // const context: AuthContextInterface = {
  //   error,
  //   user: authUser ? authUser : user,
  //   isAuthenticated,
  //   isLoading,
  //   join,
  //   login,
  //   logout,
  //   getAccessToken,
  //   getTokenID,
  //   validateEmail,
  //   updateProfile,
  //   changeEmailStepTwo,
  //   changeEmailStepThree,
  //   rePopulateUser,
  //   customerReferralCode: referralCode,
  //   checkingReferralCode,
  //   checkReferralCode,
  //   hasSocialLogin,
  //   favourites,
  //   fetchingFavourites,
  //   fetchFavourites,
  //   addToFavourites,
  //   removeToFavourites,
  // };

  const ctx: AuthContextInterface = useMemo(
    () => ({
      ...state,
      user: state?.user ? state.user : user,
      isAuthenticated,
      isLoading,
      error,
      join,
      login,
      logout,
      getAccessToken,
      getTokenID,
      validateEmail,
      updateProfile,
      changeEmailStepTwo,
      changeEmailStepThree,
      rePopulateUser,
      checkReferralCode,
      hasSocialLogin,
      fetchFavourites,
      addToFavourites,
      removeToFavourites,
    }),
    [
      state,
      join,
      login,
      logout,
      getAccessToken,
      getTokenID,
      validateEmail,
      updateProfile,
      changeEmailStepTwo,
      changeEmailStepThree,
      rePopulateUser,
      checkReferralCode,
      hasSocialLogin,
      fetchFavourites,
      addToFavourites,
      removeToFavourites,
    ]
  );

  return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>;
};

const onRedirectCallback = (appState) => {
  if (appState?.returnTo.includes("/guest-checkout")) {
    localStorage.setItem("redirecting", "true");
    const timestamp = Date.now();
    navigate(`/cart?redirection_ts=${timestamp}`);
  } else {
    navigate(appState?.returnTo || "/");
  }
};

const AuthWrapper = ({ children }: AuthProviderProps): JSX.Element => {
  const location = isBrowser() ? useLocation() : { origin: "" };

  const auth0Config: Auth0ProviderOptions = {
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    tenantDomain: process.env.GATSBY_AUTH0_TENANT_DOMAIN,
    clientId: process.env.GATSBY_AUTH0_CLIENT_ID,
    audience: process.env.GATSBY_AUTH0_AUDIENCE,
    redirectUri: `${location.origin}/`,
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    onRedirectCallback,
  };

  return (
    <Auth0Provider {...auth0Config}>
      <AuthProvider>{children}</AuthProvider>
    </Auth0Provider>
  );
};

export default AuthWrapper;
